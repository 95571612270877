<template>
  <img :src="imgUrl" alt="位元灵感" class="shop-logo cur_p" @click="goIndex" v-bind="attrs"/>
</template>
<script setup>
import {computed, getCurrentInstance, toRefs, useAttrs} from "vue";
import {useRouter} from "vue-router";

const router = useRouter()
const props = defineProps({
  type: {
    type: String,
    default: "index",
  },
  scrollTop: {
    type: Number,
    default: 0,
  }
})
const goIndex = () => {
  router.push({path: '/'})
}
const {type, scrollTop} = toRefs(props);
const {proxy} = getCurrentInstance();
const attrs = useAttrs()
const imgUrl = computed(() => {
  if (type.value === 'graphicCourse') {
    return require('@/assets/img/index/logoNew.png');
  }
  if (scrollTop.value < 1) {
    return require('@/assets/img/shop/index/logo.png');
  } else {
    return require('@/assets/img/shop/logo.png');
  }
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shopLogo",
});
</script>

<style lang="scss" scoped>
.shop-logo {
  width: 96px;
  height: 34px;
}
</style>
